:root {
  --primary: #2a2a2a;
  --primary-opacity-10: rgba(42, 42, 42, 0.1);
  --primary-opacity-20: rgba(42, 42, 42, 0.2);
  --primary-opacity-30: rgba(42, 42, 42, 0.3);
  --primary-opacity-40: rgba(42, 42, 42, 0.4);
  --primary-opacity-50: rgba(42, 42, 42, 0.5);
  --primary-opacity-60: rgba(42, 42, 42, 0.6);
  --primary-opacity-70: rgba(42, 42, 42, 0.7);
  --primary-opacity-80: rgba(42, 42, 42, 0.8);
  --primary-opacity-90: rgba(42, 42, 42, 0.9);

  --transparent: "transparent";

  --color-blue-100: #f6f5ff;
  --color-blue-200: #e3e0ff;
  --color-blue-300: #a399ff;
  --color-blue-400: #7566ff;
  --color-blue-500: #1600e5;
  --color-blue-600: #1100b3;
  --color-blue-700: #0c0080;
  --color-blue-800: #0a0066;
  --color-blue-900: #050033;
  --color-yellow-100: #fbf9f4;
  --color-yellow-200: #f5edd6;
  --color-yellow-300: #ffe599;
  --color-yellow-400: #ffd766;
  --color-yellow-500: #ffca33;
  --color-yellow-600: #ffbd00;
  --color-yellow-700: #cc9700;
  --color-yellow-800: #997200;
  --color-yellow-900: #332600;
  --color-purple-100: #f6f1fd;
  --color-purple-200: #d4bbf6;
  --color-purple-300: #b78ef0;
  --color-purple-400: #8c4ae8;
  --color-purple-500: #6f1de2;
  --color-purple-600: #5917b5;
  --color-purple-700: #451f7a;
  --color-purple-800: #391966;
  --color-purple-900: #170a29;
  --color-orange-100: #ffefeb;
  --color-orange-200: #ffc4b3;
  --color-orange-300: #ffb199;
  --color-orange-400: #ff9d80;
  --color-orange-500: #ff6333;
  --color-orange-600: #ff3c00;
  --color-orange-700: #e53600;
  --color-orange-800: #b22a00;
  --color-orange-900: #330c00;
  --color-cyan-100: #ebfcff;
  --color-cyan-200: #b3f4ff;
  --color-cyan-300: #80edff;
  --color-cyan-400: #4ce6ff;
  --color-cyan-500: #00dbff;
  --color-cyan-600: #00c5e5;
  --color-cyan-700: #00b0cc;
  --color-cyan-800: #008499;
  --color-cyan-900: #002c33;
  --color-magenta-100: #fff5f9;
  --color-magenta-200: #ffcce2;
  --color-magenta-300: #ff80b8;
  --color-magenta-400: #ff4c9b;
  --color-magenta-500: #ff0070;
  --color-magenta-600: #cc005a;
  --color-magenta-700: #b2004e;
  --color-magenta-800: #990043;
  --color-magenta-900: #3d001b;
  --color-green-100: #f2fdf7;
  --color-green-200: #bcf5d7;
  --color-green-300: #8fefbc;
  --color-green-400: #4de593;
  --color-green-500: #20df78;
  --color-green-600: #1dc96c;
  --color-green-700: #1ab260;
  --color-green-800: #138648;
  --color-green-900: #062d18;
  --color-gray-100: #f8f9fa;
  --color-gray-200: #e9ecef;
  --color-gray-300: #dee2e6;
  --color-gray-400: #c5ccd3;
  --color-gray-500: #9da6af;
  --color-gray-600: #67737e;
  --color-gray-700: #464d53;
  --color-gray-800: #2e3338;
  --color-gray-900: #171a1c;

  --magenta-11: #fff5f9;
  --magenta-10: #520339;
  --magenta-9: #780650;
  --magenta-8: #9e1068;
  --magenta-7: #c41d7f;
  --magenta-6: #eb2f96;
  --magenta-4: #ff85c0;
  --magenta-5: #f759ab;
  --magenta-3: #ffadd2;
  --magenta-2: #ffd6e7;
  --magenta-1: #fff0f6;
  --purple-13: #d4bbf6;
  --purple-12: #5917b5;
  --purple-11: #f6f1fd;
  --purple-10: #120338;
  --purple-9: #22075e;
  --purple-8: #391085;
  --purple-7: #531dab;
  --purple-6: #722ed1;
  --purple-5: #9254de;
  --purple-4: #b37feb;
  --purple-3: #d3adf7;
  --purple-2: #efdbff;
  --purple-1: #f9f0ff;
  --blue-30: #a399ff;
  --geekblue-10: #030852;
  --geekblue-9: #061178;
  --geekblue-8: #10239e;
  --geekblue-6: #2f54eb;
  --geekblue-5: #597ef7;
  --geekblue-7: #1d39c4;
  --geekblue-4: #85a5ff;
  --geekblue-3: #adc6ff;
  --geekblue-2: #d6e4ff;
  --geekblue-1: #f0f5ff;
  --cyan-11: #ebfcff;
  --cyan-10: #002329;
  --cyan-9: #00474f;
  --cyan-8: #006d75;
  --cyan-7: #08979c;
  --cyan-6: #13c2c2;
  --cyan-5: #36cfc9;
  --cyan-4: #5cdbd3;
  --cyan-3: #87e8de;
  --cyan-2: #b5f5ec;
  --cyan-1: #e6fffb;
  --green-10: #092b00;
  --green-9: #135200;
  --green-8: #237804;
  --green-7: #389e0d;
  --green-6: #52c41a;
  --green-5: #73d13d;
  --green-4: #95de64;
  --green-3: #b7eb8f;
  --green-1: #f6ffed;
  --green-2: #d9f7be;
  --lime-10: #254000;
  --lime-9: #3f6600;
  --lime-8: #5b8c00;
  --lime-7: #7cb305;
  --lime-6: #a0d911;
  --lime-5: #bae637;
  --lime-4: #d3f261;
  --lime-3: #eaff8f;
  --lime-2: #f4ffb8;
  --lime-1: #fcffe6;
  --yellow-60: #ffbd00;
  --yellow-11: #f5edd6;
  --yellow-10: #614700;
  --yellow-9: #876800;
  --yellow-8: #ad8b00;
  --yellow-7: #d4b106;
  --yellow-6: #fadb14;
  --yellow-5: #ffec3d;
  --yellow-4: #fff566;
  --yellow-3: #fffb8f;
  --yellow-2: #ffffb8;
  --yellow-1: #feffe6;
  --gold-10: #332809;
  --gold-9: #664f12;
  --gold-8: #99781a;
  --gold-7: #cc9f23;
  --gold-6: #ffc72c;
  --gold-5: #ffd152;
  --gold-4: #ffdb78;
  --gold-3: #ffe59e;
  --gold-2: #ffefc4;
  --gold-1: #fff4d5;
  --orange-10: #612500;
  --orange-9: #873800;
  --orange-8: #ad4e00;
  --orange-7: #d46b08;
  --orange-6: #fa8c16;
  --orange-5: #ffa940;
  --orange-4: #ffc069;
  --orange-3: #ffd591;
  --orange-2: #ffe7ba;
  --orange-1: #fff7e6;
  --volcano-10: #610b00;
  --volcano-9: #871400;
  --volcano-8: #ad2102;
  --volcano-7: #d4380d;
  --volcano-6: #fa541c;
  --volcano-5: #ff7a45;
  --volcano-4: #ff9c6e;
  --volcano-3: #ffbb96;
  --volcano-2: #ffd8bf;
  --volcano-1: #fff2e8;
  --red-10: #5c0011;
  --red-9: #820014;
  --red-8: #a8071a;
  --red-7: #cf1322;
  --red-6: #f5222d;
  --red-5: #ff4d4f;
  --red-2: #ffccc7;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-1: #fff1f0;
  --blue-11: #f6f5ff;
  --blue-10: #09042e;
  --blue-9: #12095c;
  --blue-6: #2e16e6;
  --blue-7: #2512b8;
  --blue-8: #1c0d8a;
  --blue-5: #5440eb;
  --blue-4: #796aef;
  --blue-3: #9f94f3;
  --blue-2: #c5bef8;
  --blue-1: #eae8fc;
  --gray-800: #16142a;
  --gray-18: #f8f9fa;
  --gray-17: #67737e;
  --gray-16: #fbfaf6;
  --gray-15: #f2f3f7;
  --gray-14: #e4e2ed;
  --gray-13: #f4f1f5;
  --gray-12: #f6f6f6;
  --gray-11: #f9f6f9;
  --gray-10: #000;
  --gray-9: #262626;
  --gray-8: #595959;
  --gray-7: #8c8c8c;
  --gray-6: #bfbfbf;
  --gray-4: #e8e8e8;
  --gray-5: #d9d9d9;
  --gray-3: #f5f5f5;
  --gray-2: #fafafa;
  --gray-1: #fff;
  --font-blue: #1600e5;
  --active-blue: rgba(22, 0, 229, 0.05);
  --hover-background: #f8f8f8;
  --light-blue: #ede6ff;
  --border: #e9e2ec;
  --font-gray: #66667d;
  --error-red: #f25454;
  --active-background-blue: #f3f2fe;
  --background-gray: #fdfdfd;
  --dark-blue: #2e17e6;
  --disabled: #c5c5c9;
  --light-green: #8cf5d7;
  --dark-green: #138648;
  --magenta-60: #cc005a;
  --purple-70: #451f7a;
  --blue-70: #0c0080;
  --green-70: #1ab260;
  --gray-60: #f7f7f7;
  --gray: #8b8ba8;
  --gray-border: #e9ecef;
  --back-fill: #e5e5e5;
  --white: #fff;
  --blue-20: #e3e0ff;
  --magenta-20: #ffb3d4;
  --green-20: #bcf5d7;
  --blue-40: #7566ff;
  --yellow-40: #ffd766;
  --purple-40: #8c4ae8;
  --orange-40: #ff9d80;
  --cyan-40: #4ce6ff;
  --magenta-40: #ff4c9b;
  --green-40: #4de593;
  --gray-40: #c5ccd3;
  --blue-50: #1600e5;
  --yellow-50: #ffca33;
  --purple-50: #6f1de2;
  --orange-50: #ff6333;
  --cyan-50: #00dbff;
  --magenta-50: #ff0070;
  --green-50: #20df78;
  --gray-50: #9da6af;
  --new-cyan-10: #e5fbff;
  --new-blue-10: #e8e5ff;
  --new-yellow-10: #fbf9f4;
  --yellow-30: #ffe599;
  --gray-30: #dee2e6;
  --cyan-60: #00c5e5;
  --green-60: #1dc96c;
  --cyan-70: #00b0cc;
  --yellow-70: #cc9700;
  --cyan-80: #008499;
  --gray-80: #2e3338;
  --yellow-80: #997200;
  --gray-90: #171a1c;
  --yellow-90: #332600;
  --yellow-100: #cc9700;
  --gray-100: #464d53;

  --shadow-1: 0 2px 8px 0 rgba(83, 83, 83, 0.1);
  --shadow-2: 0 2px 10px 0 rgba(83, 83, 83, 0.2);
  --shadow-3: 0 2px 15px 0 rgba(83, 83, 83, 0.2);

  --spacing-0: 2px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;

  --font-family: Inter, sans-serif;

  --copy-xs-regular: 12px/16px var(--font-family);
  --copy-xs-medium: 500 12px/16px var(--font-family);
  --copy-xs-semi-bold: 600 12px/16px var(--font-family);
  --copy-xs--bold: 700 12px/16px var(--font-family);
  --copy-s-regular: 14px/20px var(--font-family);
  --copy-s-medium: 500 14px/20px var(--font-family);
  --copy-s-semi-bold: 600 14px/20px var(--font-family);
  --copy-s-bold: 700 14px/20px var(--font-family);
  --copy-base-regular: 16px/24px var(--font-family);
  --copy-base-medium: 500 16px/24px var(--font-family);
  --copy-base-semi-bold: 600 16px/24px var(--font-family);
  --copy-base-bold: 700 16px/24px var(--font-family);

  --spacing-xxs: 0.25rem; /* 4px */
  --spacing-xs: 0.5rem; /* 8px */
  --spacing-s: 0.75rem; /* 12px */
  --spacing-m: 1rem; /* 16px */
  --spacing-l: 1.25rem; /* 20px */
  --spacing-xl: 1.5rem; /* 24px */
  --spacing-xxl: 1.75rem; /* 28px */
  --spacing-xxl-2: 2rem; /* 32px */
  --spacing-xxl-3: 2.25rem; /* 36px */
  --spacing-xxl-4: 2.5rem; /* 40px */
  --spacing-xxl-5: 2.75rem; /* 44px */
  --spacing-xxl-6: 3rem; /* 48px */
  --spacing-xxl-7: 3.25rem; /* 52px */
  --spacing-xxl-8: 3.5rem; /* 56px */
  --spacing-xxl-9: 3.75rem; /* 60px */
  --spacing-xxl-10: 4rem; /* 64px */
  --spacing-xxl-11: 4.25rem; /* 68px */

  --rounded-lg: 0.5rem;

  --max-body-width: 1440px;

  --drawer-width: 536px;

  // text size start
  --text-xs: 0.75rem; /* 12px */
  --text-sm: 0.875rem; /* 14px */
  --text-base: 1rem; /* 16px */
  --text-lg: 1.125rem; /* 18px */
  --text-xl: 1.25rem; /* 20px */
  --text-2xl: 1.5rem; /* 24px */
  --text-3xl: 1.875rem; /* 30px */
  --text-4xl: 2.25rem; /* 36px */
  --text-5xl: 3rem; /* 48px */
  --text-6xl: 3.75rem; /* 60px */
  --text-7xl: 4.5rem; /* 72px */
  --text-8xl: 6rem; /* 96px */
  --text-9xl: 8rem; /* 128px */
  // text size end

  // border size start
  --border-0: 0px;
  --border-1: 1px;
  --border-2: 2px;
  --border-4: 4px;
  --border-8: 8px;
  // border size end

  // border rounded start
  --rounded-none: 0px;
  --rounded-sm: 0.125rem; /* 2px */
  --rounded: 0.25rem; /* 4px */
  --rounded-md: 0.375rem; /* 6px */
  --rounded-lg: 0.5rem; /* 8px */
  --rounded-xl: 0.75rem; /* 12px */
  --rounded-2xl: 1rem; /* 16px */
  --rounded-3xl: 1.5rem; /* 24px */
  // border rounded end
}
