.create-project-layout {
  .step-nav {
    display: flex;
    padding: 0;
    list-style-type: none;
    gap: 50px;
    li {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: var(--primary-light);
      &.active {
        background: var(--primary);
        /* Shadow */

        border-radius: 6px;
        color: var(--white);
        padding: 12px 30px;
      }
    }
  }
  .project-form-container {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    .header {
      padding: 25px 30px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--primary);
    }
    .body {
      padding: 30px;
    }
    .footer {
      display: flex;
      gap: 10px;
      padding: 0 30px 30px;
    }
  }
}
