.create-project-task-modal-container {
  width: 720px !important;

  .content-container {
    height: 70vh;
    overflow-y: auto;

    .input-field-container {
      margin-bottom: var(--spacing-s);

      .ant-radio-checked {
        .ant-radio-inner {
          border-color: #367a9f;
          &::after {
            background-color: #367a9f;
          }
        }
      }
    }
  }
}
