.project-insights-container {
  width: 100%;

  .items-container {
    width: 100%;
    .card {
      background: var(--white);
      border-radius: 10px;
      padding: calc(8px + 1vw) calc(10px + 0.5vw);
      p {
        font-weight: 600;
        font-size: calc(30px + 1vw);
        line-height: 52px;
        text-transform: capitalize;
        white-space: nowrap;
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        text-transform: capitalize;
        opacity: 0.6;
      }
    }
  }
  .graph-container {
    .container {
      background: var(--white);
      border-radius: 8px;
      min-height: 350px;
      .title-container {
        min-height: 80px;
        width: 100%;
        padding: 25px;
        margin-bottom: 30px;
        .header-text {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-transform: capitalize;
        }
        button {
          background: #bec4c8;
          &:hover {
            color: #000;
            border-color: #d9d9d9 !important;
          }
        }
      }
      .project-graph-container {
        display: flex;
        justify-content: center;
        .chart-container {
          padding-bottom: 10px;
          min-height: 250px;
          flex: 0 0 auto;
        }
      }
    }
  }
}
