.notifications-container {
  .notifications {
    background: var(--white);
    border-radius: 8px;
    .container {
      height: calc(80vh - 50px);
      overflow-y: auto;

      .notification {
        &.unread {
          background-color: var(--unread);
        }
        border-bottom: 1px solid #eaeaea;
        padding: 20px 30px;
      }
    }
  }
}
