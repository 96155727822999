.list-view-container {
  overflow: scroll;
  background-color: var(--white);
  min-height: 400px;
  padding-bottom: 20px;

  .my-grid {
    display: grid;
    grid-template-columns: 90px 250px 140px 200px 190px 190px repeat(5, 135px);
    grid-gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .my-grid-2 {
    display: grid;
    grid-template-columns: 90px 250px 250px 250px 190px 190px 160px 100px 100px;
    grid-gap: 10px;
    align-items: center;
    justify-content: space-between;
  }



  .list-view-header {
    width: fit-content;
    color: var(--gray-9);
    border-radius: 0.3rem;
    background-color: var(--white);
    padding: var(--spacing-xl);
    padding-top: var(--spacing-xxl);
    margin-bottom: var(--spacing-3);
    position: sticky;
    top: 0;
    z-index: 1;

    .header-content {
      @extend .my-grid;

      &.add-new-grid {
        @extend .my-grid-2;
      }

      .name {
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }

  .list-view-body {
    padding: 0 var(--spacing-xl);
    width: fit-content;
    max-height: calc(85vh - 80px);
    overflow-y: scroll;

    .loaders {
      @extend .my-grid;
      margin-bottom: 20px;
    }
  }
}