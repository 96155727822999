.upload-preview-container{
  width: 98%;
  margin: 3px 0 0 2px;
  border-radius: 5px ;
  background-color: #e9ebf0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .info{
    display: flex;
    flex-direction: column;
  }
  .delete{
    position: absolute;
    right: 10px;
    font-size: 1.4rem;
  }
  
  
  }