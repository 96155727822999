.task-chat {
  height: calc(100% - 64px);
  background: var(--white);
  .chat-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    text-transform: capitalize;
    color: var(--primary);
    padding: 22px 20px;
  }
  .chat-container {
    height: 100%;
    flex-direction: column;
    .comment-list {
      padding: var(--spacing-m) var(--spacing-m) 0;
      overflow-y: scroll;
      flex: 1 1;
    }

    .comment-box {
      background: #ebebeb;
      position: sticky;
      bottom: 0;
      z-index: 1;
    }
  }
}
