.modal-container {
  .ant-modal-content {
    border-radius: 8px !important;
    height: 300px;
  }
  .ant-modal-body {
    margin-right: 110px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    text-align: left !important;
    border-top: 0px !important;
  }
}
.ant-modal-body .anticon-inbox {
  color: black !important;
}
.csv-content {
  display: flex;
  justify-content: space-evenly;
  align-items: end;
  margin-top: 40px;
}
