.task-row-item-card {
  background-color: var(--white);
  color: var(--gray-9);
  padding: var(--spacing-m);
  border-radius: 0.3rem;
  margin-bottom: var(--spacing-5);
  border: 1px solid var(--color-gray-200);
  cursor: pointer;

  .card-container {
    display: grid;
    grid-template-columns: 70% 20% 30px;
    grid-gap: 3%;
    align-items: center;
    justify-content: space-around;

    .content-container {
      grid-gap: var(--spacing-m);
      gap: var(--spacing-m);
      white-space: nowrap;
      flex-wrap: nowrap;
      text-overflow: ellipsis;

      .user-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-width: 18.75rem;
        min-width: 2rem;
        color: var(--color-gray-800);
      }

      .domain {
        text-transform: capitalize;
      }

      .circle-icon {
        height: 6px;
        width: 6px;
        border-radius: 100%;
        background-color: var(--gray-30);
        margin-right: var(--spacing-xs);
      }
    }

    .created-at-container {
      padding: 0;
      font-size: 0.75rem;
      font-weight: 500;
      color: var(--color-gray-600);
    }

    .more-detail-btn {
      padding: var(--spacing-xs);
      width: 2rem;
      height: 2rem;
      background: var(--gray-1);
      border: 1px solid #e9ecef;
      border-radius: 4px;
      color: var(--color-gray-600);
    }
  }
}

.task-row-item-card:hover {
  // background: var(--color-purple-100);
  // border: 1px solid var(--color-purple-300);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  // .content-container {
  //   .user-name {
  //     color: var(--color-purple-500);
  //   }
  // }
}
