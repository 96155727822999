.permissions-table-container {
  .ant-card-head {
    padding: 0;
  }

  .ant-card-body {
    padding: var(--spacing-xl) 0;
  }
}

.permissions-table {
  width: 100%;
  border: 1px solid #eff2f7;

  tr {
    th,
    td {
      width: 33.33%;
    }
  }

  tbody {
    tr {
      background: #f9f9f9 !important;
    }
  }

  th {
    // background: #f3f3f3;
    border: 1px solid var(--color-gray-200);
    padding: 10px 5px;
  }

  td {
    border: 1px solid var(--color-gray-200);
    padding: 10px 5px;
  }
}
