.dashboard-container {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(2, 1fr);
  .container {
    background: var(--white);
    border-radius: 8px;
    min-height: 350px;
    min-width: 500px;
    .title-container {
      min-height: 80px;
      width: 100%;

      padding: 25px;
      margin-bottom: 30px;
      .header-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-transform: capitalize;
      }
      button {
        background: #bec4c8;
        &:hover {
          color: #000;
          border-color: #d9d9d9 !important;
        }
      }
    }

    .darg.ant-radio-group {
      padding: 6px 10px;
      background-color: #f0f0f0;
      border-radius: 4px;
      position: relative;
      top: -5px;
      white-space: nowrap;
      .ant-radio-button-wrapper {
        transition: all ease 0.5s;
        outline: 0;
        border: 0 !important;
        box-shadow: 0 0 !important;
        padding: 0 var(--spacing-m) !important;
        color: var(--gray-17) !important;
        margin: 0 var(--spacing-s) 0 0 !important;
        cursor: pointer;
        height: 2rem;
        background: transparent !important;

        line-height: 2;
        position: relative;
        display: inline-block;
        font-weight: 600;
        white-space: nowrap;
        text-align: center;
        touch-action: manipulation;
        font-size: 14px;

        text-transform: capitalize;
      }

      .ant-radio-button-wrapper-checked {
        background: var(--white) !important;
        color: var(--primary) !important;
        font-weight: 600;
        border: 0 !important;
        border-radius: 4px;
      }

      .ant-radio-button-wrapper:not(:first-child):before {
        background-color: transparent;
      }
    }
  }

  .left-panel {
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    .card-container {
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(2, 1fr);

      .card {
        background: var(--white);
        border-radius: 10px;
        align-self: center;
        padding: 38px 20px;
        p {
          font-weight: 600;
          // font-size: 50px;
          font-size: calc(35px + 1vw);
          line-height: 75px;
          text-transform: capitalize;
          white-space: nowrap;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-transform: capitalize;
          opacity: 0.6;
        }
      }
    }
    .project-graph-container {
      display: flex;
      justify-content: center;
      .chart-container {
        padding-bottom: 10px;
        min-height: 250px;
        flex: 0 0 auto;
        width: 95%;
      }
    }
  }

  .right-panel {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 5px 0;
    }
  }

  // Global start
  .overflow-ellipses-tag {
    font-size: 15px;
    padding: 4px 12px;
    border-radius: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: fit-content;
    max-width: 60%;
  }
  // Global end
}
@media screen and (max-width: 1290px) {
  .dashboard-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 800px) {
  .dashboard-container {
    .left-panel {
      .card-container {
        grid-template-columns: 1fr;
      }
    }
  }
}
