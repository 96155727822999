.task-details-page {
  .content-body {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    background-color: var(--white);
    padding-bottom: var(--spacing-10);

    .page-header {
    }

    .page-body {
      .section {
        margin-bottom: 50px;

        .sub-heading {
          font-size: 1.25rem;
          font-weight: 600;
          margin: 0;
        }
      }

      .label {
        color: var(--gray-7);
        font-weight: 500;
      }

      .value {
        text-transform: capitalize;
      }

      .email {
      }

      .link {
      }

      .file-icon {
        height: 48px;
        width: 48px;
      }

      .collapseable-container {
        background-color: transparent;
        border-left: 0;
        border-right: 0;

        .ant-collapse-header {
          font-size: 1.25rem;
          font-weight: 600;
        }

        .panel {
          .ant-collapse-content {
            border: none;
          }

          // .ant-collapse-content > .ant-collapse-content-box {
          //   padding: var(--spacing-4) 0;
          // }
        }

        .feedback-panel {
          .header-label {
            background: var(--gray-4);
            font-weight: 700;
          }

          .weigthage-score {
            .ant-progress-inner:not(.ant-progress-circle-gradient)
              .ant-progress-circle-path {
              stroke: var(--primary);
            }

            .ant-progress-circle {
              transform: scale(-1, 1);
            }

            .ant-progress-text {
              color: var(--gray-6);
              font-weight: 700;

              .percent {
                color: var(--gray-10);
                font-size: 1.5em;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    position: static;
    bottom: 0;

    padding: var(--spacing-4);
    background: var(--white);
    box-shadow: var(--shadow-2);
    z-index: 100;

    .status {
      line-height: 1.5715;
      white-space: nowrap;
      text-align: center;
      height: 40px;
      padding: 6.4px 15px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
}

.slideIn{
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
.slideOut{
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(120%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}