.freelancer-card {
  background: var(--white);
  cursor: pointer;
  border-radius: 6px;
  padding: 22px 18px;
  position: relative;
  height: fit-content;
  .user-details {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .name {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: var(--primary);
    }
    .email {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;

      color: var(--primary-light);
    }
    .profile-status {
      .ant-tag {
        background: transparent;
        padding: 7px 24px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 4px;
      }
    }
  }
}
