.table-container {
  background-color: var(--white);
  min-height: 400px;
  max-height: calc(100vh - 80px);
  overflow: auto;
  width: 100vw;

  table {
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--white);

    th {
      // padding: 30px;
      /* padding: calc(10px + 1vw); */
      padding: 35px 15px 35px 40px;
      white-space: nowrap;
      text-align: left;
    }
  }

  tbody {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // padding: 30px;
    /* padding: calc(10px + 1vw); */
    padding: 35px 15px 35px 40px;

    .ant-tag {
      cursor: default;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */
      padding: 6px 20px;
      text-transform: uppercase;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }

    .ant-tooltip-inner {
      width: min-content;
    }
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    p.low-opacity {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-transform: capitalize;
      color: var(--primary-light);
    }

    span.low-opacity {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: capitalize;
      color: var(--primary-light);
    }
  }
}

.table-container::-webkit-scrollbar-track-piece:start {
  margin-top: 80px;
}

.table-container::-webkit-scrollbar-track:vertical:hover {
  background: var(--white) !important;
}
