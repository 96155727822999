@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import "variables";
@import "base";
// @import "sizing";
// @import "spacing";
// @import "typography";

// @tailwind base;
// @tailwind components;
@tailwind utilities;

:root {
  --blue: #00cfe8;
  --indigo: #6610f2;
  --purple: #7367f0;
  --pink: #e83e8c;
  --red: #ea5455;
  --orange: #ff9f43;
  --yellow: #ffc107;
  --green: #28c76f;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --primary-bg: #f4f4f4;
  --gray: #b8c2cc;
  --gray-dark: #1e1e1e;
  --primary: #2a2a2a;
  --primary-light: #676767;
  --primary-open-ai: #00af82;
  --secondary: #82868b;
  --success: #28c76f;
  --info: #00cfe8;
  --unread: #e3f0fe;
  --warning: #ff9f43;
  --danger: #ea5455;
  --light: #f6f6f6;
  --dark: #4b4b4b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  letter-spacing: 0.01rem;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.--main-container {
  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #6e6b7b;
  }

  .trigger:hover {
    color: $primary;
  }

  .ant-layout {
    background-color: var(--primary-bg);
  }
}

.app-container {
  grid-column: 2;
  height: calc(100vh - 64px);
  overflow: hidden;
  overflow-y: auto;
}

.content-body {
  height: 100%;
  padding: var(--spacing-xxl-6);
  overflow-y: auto;
  color: #000000;

  p {
    margin: 0;
  }

  span.low-opacity {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--primary);
    opacity: 0.6;
  }

  .view-buttons {
    border-radius: 8px;
    background-color: var(--white);
    padding: 14px 0;
    z-index: 1;
    @extend .pri-box-shadow;

    .ant-radio-group {
      margin-left: 30px;
      display: flex;
      // flex-wrap: nowrap;
      flex-wrap: wrap;
      gap: 20px;

      .ant-radio-button-wrapper {
        outline: 0;
        border-radius: 4px;
        border: 0;
        // border: solid 0.5px var(--primary) !important;
        margin: 0 !important;
        padding: 0.65rem var(--spacing-m) !important;
        cursor: pointer;
        height: 45px;
        line-height: 2;
        position: relative;
        display: inline-block;
        font-weight: 600;
        white-space: nowrap;
        text-align: center;
        touch-action: manipulation;
        font-size: 14px;
        text-transform: capitalize;

        &:not(.ant-radio-button-wrapper-checked):hover {
          background: var(--primary) !important;
          color: var(--white) !important;
          border: solid 0.5px var(--primary) !important;
        }
      }

      .ant-radio-button-wrapper-checked {
        @extend .pri-box-shadow;
        background: var(--primary) !important;
        color: var(--white) !important;
        border: solid 0.5px var(--primary) !important;
      }

      .ant-radio-button-wrapper:not(:first-child):before {
        background-color: transparent;
      }
    }
  }
}

.page-header {
  .page-heading {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
  }

  .page-heading-small {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }

  margin-bottom: var(--spacing-xxl);
}

.page-subheading {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--primary);
}

.description {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--primary);
  opacity: 0.6;
}

.text-primary {
  color: var(--primary);
}

.--avatar {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  font-size: 15px;

  &.small {
    width: 25px;
    height: 25px;
    font-size: 13px;
  }

  &.green {
    background-color: rgba(40, 199, 111, 0.12);
    color: var(--green);
  }

  &.orange {
    background-color: rgba(255, 159, 67, 0.12);
    color: var(--warning);
  }

  &.purple {
    background-color: rgba(115, 103, 240, 0.12);
    color: var(--purple);
  }

  &.red {
    background-color: rgba(234, 84, 85, 0.12);
    color: var(--red);
  }

  &.cyan {
    background-color: rgba(0, 207, 232, 0.12);
    color: var(--cyan);
  }

  &.pink {
    background-color: rgba(247, 138, 188, 0.12);
    color: var(--pink);
  }

  &.yellow {
    background-color: rgba(255, 247, 0, 0.13);
    color: var(--yellow);
  }

  &.circle {
    border-radius: 50%;
  }
}

.page-body {
  margin: var(--spacing-xxl) 0;
}

.ant-checkbox-group {
  width: 100%;

  label {
    width: 100%;

    .ant-checkbox {
      width: 15.9px;
    }

    span:nth-child(2) {
      width: 100%;
    }
  }
}

.bg-white {
  background-color: var(--white);
}

.c1 {
  background-color: #17a2b8;
}

.c2 {
  background-color: #7367f0;
}

.c3 {
  background-color: yellowgreen;
}

.overflow-ellipse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.pri-box-shadow {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06) !important;
}

.of-ellipse-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pri-bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.sec-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary);
  margin: 0;
}

.tert-bold {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--primary);
}

.pri-light {
  font-weight: 500;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: var(--primary-light);
}

.new_search {
  padding: 13px 10px 13px 20px;
  height: 46px;
  border-radius: 6px !important;
}

button.new_button {
  @extend .pri-box-shadow;
  border-radius: 6px !important;
  height: 45px;
}

.wpsb.ant-select {
  .ant-select-selector {
    border-radius: 6px !important;
    height: 45px !important;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      margin: auto 0;
    }
  }
}

.mch.ant-select {
  .ant-select-selector {
    border-radius: 6px !important;
    height: auto !important;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      max-height: 60px !important;
    }
  }
}

.custom_tag.ant-tag {
  cursor: default;
  font-weight: 600;
  font-size: 12px;

  padding: 6px 20px;
  text-transform: uppercase;
  @extend .pri-box-shadow;
  border-radius: 4px;
}

.transparent_picker {
  height: 46px !important;
  padding: 6.5px 15px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  background: transparent !important;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

.new_select.ant-select {
  .ant-select-selector {
    margin: auto 0 !important;
    background: var(--primary) !important;
    height: 45px !important;
    @extend .pri-box-shadow;
    border-radius: 6px !important;

    &:not(.ant-select-disabled):hover {
      border-color: transparent;
    }

    .ant-select-selection-search {
      color: var(--white) !important;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      color: var(--white) !important;
      margin: auto 0;
      padding: 0 auto;
    }
  }

  .ant-select-arrow {
    color: var(--white);
  }
}

.container-shadow {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.bg-primary {
  background: var(--primary) !important;
}

.sticky {
  position: sticky !important;
}


.color-primary {
  color: var(--primary-light);

  &:hover {
    color: var(--primary-light);
  }
}

.link-cb {
  i {
    color: #1890ff;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;

    &:hover {
      color: #40a9ff;
    }

    &:active {
      color: #096dd9;
    }
  }
}

.list-item-filter {
  list-style: none;
  margin: 0;
  padding: 0px;
  min-width: 130px;

  li {
    padding: 5px 10px;
    cursor: pointer;

    &.active {
      border: 1px dashed #ccc;
      background: #f1f1f1;
    }
  }
}