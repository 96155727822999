.assessment-container {
  background-color: var(--primary-bg);
  width: 100%;
  height: 100%;
  padding: 50px;
  overflow-y: auto;

  .test-container {
    display: grid;
    grid-template-columns: 1fr;

    border-radius: 8px;
    .modal-header {
      padding: 25px 30px;

      .heading {
        font-weight: 600;
        font-size: 20px;
        text-transform: capitalize;
        color: var(--primary);
      }
      .sub-heading,
      .ant-statistic-content-value {
        font-weight: 500;
        font-size: 16px;
        color: rgba(42, 42, 42, 0.6);
      }
    }
    .modal-body {
      min-height: 60vh;
      padding: 30px;

      .ant-progress-text {
        color: #000 !important;
      }
      .level {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      .test-option {
        border: 1px solid rgba(103, 103, 103, 0.5);
        border-radius: 6px;
        margin-bottom: 25px;
        padding: 20px;
        font-weight: 500;
        font-size: 16px;
        color: rgba(42, 42, 42, 0.6);
        align-items: center;
        width: 100%;
      }
      .question {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary);
      }
    }
    .modal-footer {
      padding: 30px;
    }
  }
}
