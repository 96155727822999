.profile-container {
  .page-content {
    background: var(--white);
    border-radius: 5px;
    margin-top: 15px;
    padding: 24px;
    label {
      color: #333;
      font-weight: 700;
      text-transform: capitalize;
    }

    .form-container {
    }

    .side-image-container {
      .image-container {
        .image {
          width: 250px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .profile-container .page-content {
    padding: 24px 15px;

    .nw_btn_white.ant-btn,
    .ant-modal-footer .ant-btn-default {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}
