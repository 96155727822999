.project-details-container {
  .project-details {
    background-color: var(--white);
    padding: 25px 31px;
  }

  .sub-header {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    text-transform: capitalize;
    color: #666666;
  }

  .page-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    text-transform: capitalize;
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.set-text-icon {
      display: flex;
      gap: 10px;
    }
  }

  .line {
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  .task-status {
    padding: 6px 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
    background: transparent;

    &.red {
      border: 1px solid #ea605a;
      color: #ea605a;
    }

    &.green {
      border: 1px solid #00af82;
      color: #00af82;
    }
  }

  .project-task-lists-container {
    width: 100%;
    overflow-x: scroll;
    display: grid;
    grid-template-columns: repeat(4, minmax(320px, 1fr));
    grid-gap: var(--spacing-xxl-2) var(--spacing-xl);
    margin-bottom: 20px;
  }
}

.custom-button {
  padding: 1px 6px;
  background: #999999;
  cursor: pointer;
  /* Shadow */
  border-radius: 6px;
  border: 0;
  min-height: 45px;

  &.active {
    background-color: var(--primary);
  }
}