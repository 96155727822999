.todo-list-container {
  display: grid;
  padding: 0 30px 30px;
  grid-gap: 30px;
  align-items: flex-start;
  width: 100%;

  .task {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    min-width: 0;
    padding-bottom: 25px;
    border-bottom: 0.1px solid rgba(0, 0, 0, 0.2);
    p {
      padding: 4px 0;
      &.pri-color {
        color: var(--primary);
      }
    }

    .nl {
      line-height: normal !important;
      margin: 0;
      padding: 0;
    }
    .pgc {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: end;
      .ant-progress {
        margin: 5px 15px 0 0;
        .ant-progress-text {
          font-weight: 700;
          font-size: 15px;
          line-height: 21px;
          /* identical to box height */
          color: var(--primary);
        }
      }
    }
  }
  button {
    background: #bec4c8;
    border-radius: 8px;
    height: 60px;
    &:hover {
      color: #000;
      border-color: #d9d9d9 !important;
    }
  }
}
