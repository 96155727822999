.custom-modal {
  .ant-modal-content {
    border-radius: 8px;
    height: 400px !important;
  }

  .ant-modal-title {
    font-size: 15px !important;
  }
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-footer {
    // display: none;
    justify-content: center !important;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: red !important;
    }
  }
}

.indicator-container {
  .hidden-icon {
    background-color: gray;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

.custom-modal > .ant-modal-content {
  border-radius: 0px !important;
}

.content > .ant-modal-header > .ant-modal-title {
  font-size: 15px !important;
}

.timer-icon {
  position: absolute;
  right: 81px;
  top: 47px;
  /* justify-content: end; */
  gap: 10px;
  .time-text {
    padding-left: 10px;
  }
}
.custom-list {
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    padding: 10px; /* Space around the content */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 15px; /* Space between list items */
  }

  li > .anticon {
    margin-right: 8px; /* Space between the icon and the text */

    color: white;
    padding: 2px;
  }
  .right-checked {
    background-color: green !important;
  }
  .cross-checked {
    background-color: red;
  }
}

// FileProcessing.scss
.custom-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-list li {
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.green-bg {
  background-color: #e8f5e9; // Light green background
}

.blue-bg {
  background-color: #e3f2fd; // Light blue background
}
.gray-bg {
  background-color: lightgray;
}

// To remove border/outline from icons
.anticon {
  border: none; // Remove any borders
  outline: none; // Remove any outlines
}

.timer-icon {
  display: flex;
  align-items: center;
  gap: 10px; // Space between icon and text
}

.time-text {
  font-size: 16px; // Adjust size as needed
}

// FileProcessing.scss
.custom-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-list li {
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.checkbox-container {
  padding: 5px;
  border-radius: 5px;
}

// Apply red background to specific checkboxes
.red-bg {
  background-color: #ffebee; // Light red
}
.green-bg {
  background-color: lightblue;
}

.timer-icon {
  display: flex;
  align-items: center;
  gap: 10px; // Space between icon and text
}

.time-text {
  font-size: 16px; // Adjust size as needed
}
