.chat-item {
  .chat-item-container {
    flex-wrap: nowrap;
    padding: var(--spacing-xs);

    .chat-details-container {
      width: 100%;
      margin-left: var(--spacing-s);
      .user-details {
        .name {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          text-transform: capitalize;
          color: var(--primary);
        }
      }
      .timestamp {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: var(--primary);
        opacity: 0.6;
      }

      .chat-details {
        .message {
          font-size: 0.875rem;
          font-weight: 500;
          color: var(--primary-light);
        }

        .file-list {
          margin-top: var(--spacing-l);
        }
      }
    }
  }
}
