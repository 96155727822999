.custom-link {
  text-decoration: none;
  color: rgb(31, 41, 55);
  //   font-size: 20px;
  //   margin-right: 20px;
  //   font-weight: 600;

  font-size: 14px;
  text-transform: capitalize;
}
