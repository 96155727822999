.testing-container {
  display: flex;
  justify-content: center;
  padding: 2.5rem;


  .sidebar-container {
    min-width: 300px;
    max-width: 400px;
    width: 100%;
  }

  .card-header-top {
    display: flex;
    align-items: baseline;

    margin-bottom: 20px;
  }

  .ant-space {
    margin-bottom: 0.5em !important;
  }

  .ant-input {
    resize: none;
  }

  .card-header {
    .ant-input {
      margin-bottom: 20px;
    }
  }

  .chat-container {
    height: 98%;
  }

  .ant-card {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    flex: 1 0 0%;
  }

  .card-header {
    margin-bottom: 20px;
  }

  .ant-card-head {
    background: rgb(235, 235, 235);
    display: flex;
    justify-content: center;
    min-height: 78px;
    align-items: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    -webkit-border-radius: 8px;

    .ant-space {
      margin-bottom: 0px !important;
    }
  }

  .textarea-wrapper {
    position: relative;
    margin-bottom: 16px;
    // .icon-space {
    //   position: absolute;
    //   top: -36px; // adjust based on desired position
    //   right: 8px; // adjust based on desired position
    //   z-index: 3;
    // }
  }

  .textarea-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;


    /* h3 {
      flex: 1 0 0%;
      padding-right: 20px;

      input,
      input:focus,
      input:focus-visible {
        width: 100%;
        font-size: 14px;
        padding: 5px 10px;
        border: 1px solid #d9d9d9;
        outline: 0px;
      }
    } */
  }

  .card-footer {
    text-align: center;
    width: 100%;
  }

  .textarea-element {
    min-height: 150px;
  }

  .edit-text-cb {
    flex: 1 0 0%;
    width: 100%;
    margin-bottom: 10px;
    height: 44px;


  }


  /* chat task scss */

  .task-chat {
    height: calc(100% - 64px);
    background: var(--white);

    .chat-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */
      text-transform: capitalize;
      color: var(--primary);
      padding: 22px 20px;
    }

    .chat-container {
      height: 100%;
      flex-direction: column;

      .comment-list {
        padding: var(--spacing-m) var(--spacing-m) 0;
        overflow-y: scroll;
        flex: 1 1;
      }

      .comment-box {
        background: #ebebeb;
        position: sticky;
        bottom: 0;
        z-index: 1;
      }
    }
  }

  .comment-box {
    .comment-container {
      z-index: 2;
      width: 100%;
      flex-flow: nowrap;
      padding-right: 5px;

      .input-container {
        width: 100%;
        height: 80%;
        overflow: hidden;

        .custom-mention {
          background: transparent;
          border: 0;
          height: 78px;
          padding: 15px;

          .rc-textarea {
            border: 0;
            background: transparent;
          }
        }
      }

      .action-btn-container {
        flex-flow: nowrap;
        margin-bottom: var(--spacing-xs);

        .attachment-btn-container {
          margin: 0 var(--spacing-m);

          .attachment-btn {
            height: auto;
            background: none;
            font-size: 0.875rem;
            border-radius: 4px;
            border: none;
          }

          button svg {
            cursor: pointer;
            margin-top: var(--spacing-xxs);
            color: var(--font-gray);
          }
        }
      }
    }
  }
}