.m-pagination {
  display: block;
  text-align: center;

  .ant-pagination-prev,
  .ant-pagination-next {
    margin: var(--spacing-xs) !important;

    .ant-btn {
      box-shadow: none !important;
    }
  }

  .ant-pagination-prev {
    margin-right: var(--spacing-m) !important;
  }

  .ant-pagination-next > .ant-pagination-item-link,
  .ant-pagination-prev > .ant-pagination-item-link {
    border: none !important;
    background-color: transparent;
  }

  .ant-pagination-next > .ant-pagination-item-link:hover,
  .ant-pagination-prev > .ant-pagination-item-link:hover {
    color: var(--primary);
  }

  .ant-pagination {
    .ant-pagination-item {
      min-width: 24px;
      height: 24px;
      margin: 0;
      line-height: 22px;
    }
  }

  .ant-pagination-item-active a {
    color: #fff !important;
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    background: var(--primary);
    border-color: var(--primary);
  }

  .ant-pagination-next .ant-pagination-item-link:hover,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: var(--primary);
  }

  .ant-pagination-item:hover {
    border-color: var(--primary);
  }

  .ant-pagination-disabled {
    .ant-btn[disabled],
    .ant-btn[disabled]:active,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:hover {
      color: rgba(0, 0, 0, 0.25);
      background-color: transparent;
    }
  }
}
