.task-list-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--spacing-xxl-11) !important;

  .page-header {
    max-width: 90% !important;
  }

  .page-body {
    // flex: 1 1;
    max-width: 90% !important;
    z-index: 1;
  }
}
